import React, { FC, useState, useEffect, ChangeEvent, memo } from 'react';

import { Box, Button, Typography, Stack } from '@mui/material';

import Tooltip from '@shared/components/Tooltip';
import type { FileEventTarget } from '@shared/types';

import { readFile } from '../file-utils';

import CropperOverlay from './CropperOverlay';
import type { MobileCropperProps } from './mobile-cropper.types';

import styles from '../image-cropper.module.scss';

const MobileCropper: FC<MobileCropperProps> = ({
  onChange,
  value,
  maskImage,
  aspectRatio,
  isDisabled = false,
  label,
  toolTipContent,
  containerClassName = '',
  fieldName,
  onCroppedPixelsChange,
}) => {
  const [open, setOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState<string>('');
  const [file, setFile] = useState<File>();
  const [silentMode, setSilentMode] = useState(false);

  useEffect(() => {
    if (!value) {
      setImageSrc('');
      return;
    }

    if (typeof value === 'string') {
      setSilentMode(true);
      setImageSrc(value);
    }
    // else {
    //   setFile(value);
    //   readFile(value).then((res) => {
    //     console.log(res);
    //     setImageSrc(res)
    //   }).catch((e) => {
    //     console.log(e);
    //     setErrorImage(true);
    //     console.log(errorImage);
    //     setImageSrc(imageSrc);
    //   });;
    // }
  }, [value]);

  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (silentMode && imageSrc) {
      handleOpen();
    }
  }, [silentMode, imageSrc]);

  const [errorImage, setErrorImage] = useState(false);
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const target = e.target as FileEventTarget;

    if (target.files && target.files.length > 0) {
      setSilentMode(true);
      const temp_file = target.files[0];
      readFile(temp_file)
        .then((res) => {
          setImageSrc(res);
          setFile(temp_file);
          onChange(temp_file);
        })
        .catch((e) => {
          setErrorImage(true);

          setImageSrc(imageSrc);
        });
    } else {
      setErrorImage(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setSilentMode(false);
  };

  const handleConfirmClick = (croppedImage: File | string | null) => {
    if (!croppedImage) {
      return;
    }

    if (typeof croppedImage === 'string') {
      setImageSrc(croppedImage);
    } else {
      setFile(croppedImage);
      readFile(croppedImage).then(setImageSrc).catch(console.error);
    }

    onChange(croppedImage);
  };

  const getFileName = () => {
    if (file) {
      return file.name;
    }

    if (imageSrc) {
      return imageSrc.split('/').pop() || imageSrc;
    }

    return 'Upload Image';
  };

  const handleClearClink = () => {
    setImageSrc('');
    setFile(undefined);

    onCroppedPixelsChange(fieldName, null);

    onChange(null);
  };

  return (
    <div className={`${styles.imageCropperWrapper} ${containerClassName}`}>
      {errorImage && (
        <Box className={styles.errorModalWrapper}>
          <Box component="div" className={styles.errorModal}>
            <Typography variant="h6" noWrap className="cropperLabel">
              Image should be less than 5.000 px
            </Typography>
            <Box className={styles.errorModalBtnWrapper}>
              <Button
                color="error"
                size="small"
                className={styles.errorModalBtn}
                onClick={() => setErrorImage(false)}
              >
                Close
              </Button>
            </Box>
          </Box>
        </Box>
      )}
      <Stack className={styles.imageCropperHeader} spacing={1}>
        <Stack
          className={`${styles.imageCropperHeader} tooltipWrapperInline`}
          direction="row"
        >
          <Typography variant="h6" noWrap className="cropperLabel">
            {label}
          </Typography>
          {!!toolTipContent && (
            <Tooltip className="tooltip" content={toolTipContent} />
          )}
        </Stack>

        {imageSrc && (
          <Box component="div" className={styles.cropArea}>
            <Box
              className={styles.imagePreview}
              component="img"
              src={imageSrc}
            />
          </Box>
        )}

        <Stack spacing={1} className={styles.actionButtons}>
          <Button
            variant="contained"
            size="small"
            disabled={isDisabled}
            component="label"
            className="uploadBtn"
          >
            <span className="uploadBtnText">{getFileName()}</span>
            <input
              accept="image/*"
              type={'file'}
              hidden
              onChange={handleFileChange}
            />
          </Button>
          {imageSrc && (
            <Button
              size="small"
              disabled={isDisabled}
              variant="outlined"
              color="success"
              onClick={handleOpen}
            >
              Zoom/Crop
            </Button>
          )}
          {imageSrc && (
            <Button
              size="small"
              disabled={isDisabled}
              color="error"
              onClick={handleClearClink}
            >
              Clear
            </Button>
          )}
        </Stack>
      </Stack>

      {open && (
        <CropperOverlay
          onConfirm={handleConfirmClick}
          onClose={handleClose}
          isOpen={open}
          imageSrc={imageSrc}
          file={file}
          maskImage={maskImage}
          aspectRatio={aspectRatio}
          fileName={getFileName()}
          silentMode={silentMode}
          fieldName={fieldName}
          onCroppedPixelsChange={onCroppedPixelsChange}
        />
      )}
    </div>
  );
};

export default memo(MobileCropper);
