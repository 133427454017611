import React, { FC, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';

import {
  Container,
  TextField,
  Stack,
  Button,
  FormHelperText,
  Grid,
} from '@mui/material';

import { useLogin } from './login.actions';
import { FormInputs } from './login.types';

import styles from './login.module.scss';

const LoginPage: FC = () => {
  const [response, handleLoginSubmit, loading, loginError] = useLogin();

  const {
    handleSubmit,
    formState: { errors },
    setError,
    control,
  } = useForm<FormInputs>();

  // useEffect(() => {
  //   console.error(loginError)
  //   setError('root.loginError', {
  //     message: loginError.message,
  //   });
  // }, [setError, loginError, response]);

  const onSubmit = (data: FormInputs) => {
    handleLoginSubmit(data);
  };

  return (
    <Container
      sx={{
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        justifyContent: 'center',
      }}
      maxWidth="xl"
    >
      <form onSubmit={(...args) => void handleSubmit(onSubmit)(...args)}>
        <Grid className={styles.gridCenterItems} container item xs={12}>
          <Grid item xs={12} sm={10} md={6}>
            <Stack className={styles.gridAlignItems} spacing={2}>
              <Controller
                name="key"
                defaultValue=""
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    className={styles.loginInput}
                    error={!!errors?.key}
                    label="Введите ваш ключ"
                  />
                )}
              />
              {errors.root && errors.root.loginError && (
                <FormHelperText error>{loginError.message}</FormHelperText>
              )}
              <Button
                className={styles.btnLogin}
                disabled={loading}
                size="small"
                type="submit"
                variant="contained"
              >
                Войти
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default LoginPage;
