import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Box } from '@mui/material';

import { UnityComponent } from '@shared/components';

import {
  useFetchGameBuild,
  useFetchGameDefaultsLazy,
} from '../Client/client.actions';

import { useIncreaseGameBuildRunCount } from './game.actions';

import './index.scss';

const GamePage = () => {
  const [searchParams] = useSearchParams();
  const [_, increaseGameBuildRunCount] = useIncreaseGameBuildRunCount();

  const currentBuildId = searchParams.get('buildId');

  useEffect(() => {
    if (currentBuildId !== null) {
      increaseGameBuildRunCount(currentBuildId);
    }
  }, []);

  const [gameBuild] = useFetchGameBuild(currentBuildId ?? '');

  const { gameName = '' } = gameBuild ?? {};

  const [gameDefaults, fetchGameDefaultsLazy, loading] =
    useFetchGameDefaultsLazy();

  useEffect(() => {
    if (gameName !== '') {
      fetchGameDefaultsLazy(gameName);
    }
  }, [gameName]);

  const unityResolution = 1920 / 1080;

  return (
    <Box
      className="mainPage"
      style={{ backgroundColor: gameDefaults?.backgroundColor ?? 'white' }}
    >
      <UnityComponent
        loaderUrl="../webgl-build/loader.js"
        dataUrl="../webgl-build/game.data"
        frameworkUrl="../webgl-build/framework.js"
        codeUrl="../webgl-build/code.wasm"
        resolution={unityResolution}
      />
    </Box>
  );
};

export default GamePage;
