export const TOOLTIPS_CONTENT = {
  startHeader:
    'Текст на заставку. Рекомендуется до 5 слов для корректного отображения',
  endHeader:
    'Текст на финал. Рекомендуется до 5 слов для корректного отображения',
  startImage:
    'Фото на заставку',
  endImage:
    'Фото на финал',
  gameTiles: 'Эти фото будут размещены на карточках',
  timeAndAttempts:
    'Включить 180 секундный таймер, Если не соберете все карточки за это время, игра автоматически включит финальный экран',
  customMusic:
    'Формат MP3 или WAV. Размер до 5мб',
  testGame: 'Демо режим. Игра соберет карточки за вас',
  createGame:
    'После нажатия игра начнет создавать и вы получите ссылку на готовую игру',
  gameLink: 'Ссылка на готовую игру',
};

export const REQUIRED_TILES_AMOUNT = 3;
